<template>
  <div class="" style="position: relative; background-color: #e7e6e6">
    <div class="ps-2">
      <HeaderPage />
      <!-- <UserName /> -->
    </div>

    <div style="width: 100%; height: auto; background-color: white">
      <div v-if="isLogin == 0" style="padding: 60px 0 0 10px; margin-bottom: -60px">
          <v-btn
            dark
            depressed
            @click="toLogin"
          >
            {{ $t("message.label-login") }}
          </v-btn>
        </div>
      <v-container>
        <v-row
          style="padding-right: 0px"
          justify="space-between"
          class="top-banner"
        >
          <v-col
            class="font-weight-medium"
            style="font-size: 18px; padding: 0 0 0 5px"
          >
            <div style="font-weight: bold" v-if="user">{{ user }}さま</div>
          </v-col>
        </v-row>
      </v-container>
      <Banner
        :show_arrow="false"
        :data_slide="holidaysslideitems"
        :is_loading="isLoadingBanner"
      />
      <Banner
        :show_arrow="false"
        :data_slide="tajimingoslideitems"
        :is_loading="isLoadingBanner"
      />

      <!-- <Section
        :title_name="'新規開店'"
        :sub_name="'NEW Shop'"
        :has_show="false"
      /> -->
      <Banner
        :show_arrow="true"
        :data_slide="shopslideitems"
        :is_loading="isLoadingShopBanner"
      />

      <div v-if="isLogin == 1">
        <StoreSlide
          :data_store="favStore"
          :title_slide="'お気に入り店舗'"
          :link_slide="'/favorite-store'"
          :is_loading="isLoadingFavStore"
          :sub_name_section="'Favorite Store'"
          :has_show_section="true"
        />
      </div>

      <div v-if="shopNews.length > 0">
        <Section
          :title_name="'店舗からのお知らせ'"
          :sub_name="'Shop NEWS'"
          :has_show="true"
          :link="'/shop-news'"
        />

        <ShopNews :data_news="shopNews" :loading="isLoadingNews" />
      </div>

      <v-list-item class="mt-auto"></v-list-item>
    </div>

    <!-- <keep-alive> -->

    <div style="margin-bottom: 5em">
      <!-- <HeaderNavigasi /> -->

      <!-- <div class="" style="margin-top: 18px">
        <ProductSlide
          :data_slide="listItemRecomend"
          :title_slide="$t('message.recommended-products')"
          :link_slide="'/recommendation'"
          :is_loading="isLoadingRec"
        />
      </div> -->

      <!-- ===== HIDE ITEMLIST PICKUP ====== -->
      <!-- <div v-if="isLogin == 1"> -->
      <!-- <div class="" style="margin-top: 18px">
              <ProductSlide
                :data_slide="itemListPickup"
                :title_slide="$t('message.label-pickup')"
                :is_loading="isLoadingPickup"
                :data_store="[]"
              />
            </div> -->

      <!-- ITEMLIST PICKUP BY STORE  -->
      <!-- <div v-for="(pickupByStore, i) in itemListPickupByStore" :key="i">
        <div class="" style="margin-top: 18px">
          <ProductSlide
            :data_slide="pickupByStore.items"
            :title_slide="pickupByStore.store_name"
            :label_slide="'ショップページへ'"
            :link_slide="`/store/${pickupByStore.store_id}`"
            :is_loading="isLoadingPickup"
            :data_store="[]"
          />
        </div>
      </div> -->
      <!-- </div> -->
      <div v-if="isLogin == 1">
        <div class="" style="margin-top: 18px">
          <ProductSlide
            :data_slide="listProductFav"
            :title_slide="$t('message.title-favorite')"
            :link_slide="'/wishlist'"
            :is_loading="isLoadingFav"
            :has_show_section="true"
          />
        </div>
      </div>

      <div v-if="isLogin == 1">
        <div class="" style="margin-top: 40px">
          <ProductSlide
            :data_slide="listProductLastSeen"
            :title_slide="$t('message.last-seen-products')"
            :link_slide="'/last-seen'"
            :is_loading="isLoadingLast"
            :has_show_section="true"
          />
        </div>
      </div>
      <div v-if="isLogin == 1">
        <div class="" style="margin-top: 40px">
          <StoreSlide
            :data_store="orderHistoryStore"
            :title_slide="$t('message.order-history-store')"
            :link_slide="'/order-history-store'"
            :is_loading="isLoadingHStore"
            :has_show_section="true"
          />
        </div>
      </div>
      <div v-if="isLogin == 1">
        <div class="" style="margin-top: 18px">
          <ProductSlide
            :is_loading="isLoadingHProduct"
            :data_slide="orderHistoryItem"
            :title_slide="$t('message.order-history-product')"
            :link_slide="'/order-history'"
            :has_show_section="true"
          />
        </div>
      </div>

      <!-- <div class="class-category">
        <div class="d-flex justify-center pa-4">
          <span class="text-h6 white--text">{{
            $t("message.label-category")
          }}</span>
        </div>
        <v-container>
          <v-row v-if="isLoadingCategory">
            <v-col v-for="i in 6" :key="i" cols="6" md="2" sm="4">
              <v-card class="pa-2 d-flex justify-center" flat>
                <SkeletonShopCategory class="pa-2" />
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="text-center p-0 m-0">
            <v-col
              v-for="(category, i) in listCategory"
              :key="i"
              cols="4"
              md="2"
              sm="2"
              style="padding:3px"
            >
              <v-card class="pa-2" flat @click="toPageListCategory(category)">
                <v-img height="50" contain :src="category.image_url" />
                <div class="mt-2 style-title">
                  <span class="mt-2">{{ category.category_name }}</span>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
      <div style="margin-bottom: 8rem">
        <CategoryFooter />
      </div>
    </div>

    <!-- </keep-alive> -->

    <!-- <v-card
          class="pa-2 category-footer"
          flat
          @click="$router.push('/shopping-guide')"
        >
           {{$t("message.shopping-guide")}}
        </v-card> -->

    <!-- shoptest.tajimingo.com -->
    <!-- <div :class="!currentRouteName ? 'title-tajimiInfo': 'title-display'">
      <p style="font-size: 14px">
        {{ $t("message.label-tajimiInfo") }}
        <span class="pink--text" @click.prevent="$router.push('/tajimi-info')">
          {{ $t("message.click") }}
        </span>
      </p>
    </div>
    -->

    <Cashier />
    <div style="">
      <Sidebar />
    </div>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
// import Sidebar from "../components/Sidebar.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import Cashier from "../components/Cashier.vue";
import User from "../components/User.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import LoadingPage from "../components/LoadingPage.vue";
import SkeletonCategory from "../components/skeleton/SkeletonCategory.vue";
import SkeletonShopCategory from "../components/skeleton/SkeletonShopCategory.vue";
import ProductSlide from "../components/ProductSlide.vue";
import StoreSlide from "../components/StoreSlide.vue";
import Product from "../components/Product.vue";
import { mapGetters } from "vuex";
import StoreList from "../components/StoreList.vue";
import { mdiChevronRight } from "@mdi/js";
import Section from "../components/Section.vue";
import { mdiChevronRight as chevron_right } from "@mdi/js";
import ShopNews from "../components/ShopNews.vue";

export default {
  name: "Home",

  components: {
    Sidebar,
    ProductSlide,
    StoreSlide,
    LoadingPage,
    SkeletonCategory,
    SkeletonShopCategory,
    StoreList,
    CategoryFooter,
    UserName: User,
    HeaderNavigasi,
    Cashier,
    Product,
    Banner,
    HeaderPage,
    Section,
    ShopNews,
  },
  data() {
    return {
      user: "",
      isLoadingList: true,
      isLoadingRec: true,
      isLoadingFav: true,
      isLoadingStore: true,
      isLoadingLast: true,
      isLoadingCategory: true,
      isLoadingHProduct: true,
      isLoadingHStore: true,
      isLoadingPickup: false,
      isLoadingNews: true,
      isLoadingBanner: true,
      isLoadingShopBanner: true,
      iconViewAll: mdiChevronRight,
      isLoadingFavStore: true,
      notFound: require("../assets/image-not-found.png"),
      shopslideitems: [
        // {
        //   bannerimage: require("../assets/banner_tamanoya_20220324.jpg"),
        //   bannerlink: "/store/9",
        // },
        // {
        //   bannerimage: require("../assets/banner_tobundo_20220315.jpg"),
        //   bannerlink: "/store/8",
        // },
        // {
        //   bannerimage: require("../assets/banner_hitotemaya_20220316.jpg"),
        //   bannerlink: "/store/7",
        // },
        {
          bannerimage: require("../assets/banner_marunaka_20220324.jpg"),
          bannerlink: "/store/6",
        },
        {
          bannerimage: require("../assets/banner_oomatsu_20220330.jpg"),
          bannerlink: "/store/1",
        },
        {
          bannerimage: require("../assets/banner_marunaka_20220324.jpg"),
          bannerlink: "/store/6",
        },
        {
          bannerimage: require("../assets/banner_oomatsu_20220330.jpg"),
          bannerlink: "/store/1",
        },
      ],
      tajimingoslideitems: [
        // {
        //   bannerimage: require("../assets/banner_campaign202204_a.jpg"),
        // },
        // {
        //   bannerimage: require("../assets/banner_campaign202204_b.jpg"),
        // },
        // {
        //   bannerimage: require("../assets/banner_campaign202204_a.jpg"),
        // },
        {
          bannerimage: require("../assets/banner_20230823stores.jpg"),
        },
      ],
      holidaysslideitems: [
        {
          bannerimage: require("../assets/banner_20241127toki.jpg"),
        },
      ],
      svgPath: chevron_right,
    };
  },
  beforeMount() {
    // window.scrollTo(100000,99999)
    this.checkSessionLogin();
    this.checkUser();
  },

  methods: {
    checkUser() {
      let checkCookies = $cookies.get("isLogin");
      if (checkCookies == 1) {
        this.user = localStorage.getItem("name_user");
      } else {
        this.user = localStorage.removeItem("name_user");
        this.user = "";
      }
    },
    async itemLastSeen() {
      if ($cookies.get("isLogin") == 1) {
        this.isLoadingLast = true;
        let data = await this.$store.dispatch(
          "itemLastSeen_module/itemLastSeen"
        );
        this.isLoadingLast = false;
        return data;
      }
    },
    async itemList() {
      this.isLoadingList = true;
      let data = await this.$store.dispatch("itemList_module/itemList");
      this.isLoadingList = false;
      return data;
    },
    async shopNewsList() {
      this.isLoadingNews = true;
      this.isLoadingBanner = true;
      this.isLoadingShopBanner = true;
      let data = await this.$store.dispatch("shop_module/fetchShopNews");
      this.isLoadingNews = false;
      this.isLoadingBanner = false;
      this.isLoadingShopBanner = false;
      return data;
    },
    async category() {
      this.isLoadingCategory = true;
      let data = await this.$store.dispatch(
        "allCategory_module/fetchAllCategory"
      );
      this.isLoadingCategory = false;
      return data;
    },
    async itemFavorite() {
      this.isLoadingFav = true;
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        let data = await this.$store.dispatch(
          "itemFavorite_module/itemFavorite"
        );
        this.isLoadingFav = false;
        return data;
      }
    },
    async dispatchOrderHistory() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.isLoadingHProduct = true;
        let res = await this.$store.dispatch("order_module/orderHistoryByItem");
        this.isLoadingHProduct = false;
        return res;
      }
    },
    async dispatchOrderHistoryStore() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.isLoadingHStore = true;
        let res = await this.$store.dispatch(
          "order_module/orderHistoryByStore"
        );
        this.isLoadingHStore = false;

        return res;
      }
    },
    toPageListCategory(category) {
      if (category.is_child === 1) {
        this.$router.push(`/items/category/${category.slug}`);
      } else {
        this.$router.push(`/items/category/c/${category.slug}/1`);
      }
    },
    handleIsLogin() {
      if ($cookies.get("isLogin") == 0 && localStorage.usr_tkn) {
        localStorage.removeItem("usr_tkn");
      }
      if ($cookies.get("isLogin") == 0) {
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("user_register");
      }
    },
    checkSessionLogin() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.$store.dispatch("auth_module/authSessionLogin");
      }
    },
    async getAllStore() {
      this.isLoading = true;
      let pageInit = parseInt(
        this.$store.state.shop_module.paginationStore.page
      );
      this.page = parseInt(this.$store.state.shop_module.paginationStore.page);
      let data = await this.$store.dispatch("shop_module/fetchAllStore", {
        page: pageInit,
      });

      this.isLoading = false;
      return data;
    },
    async getFavStore() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.isLoadingFavStore = true;
        let res = await this.$store.dispatch("shop_module/fetchStoreFav");
        this.isLoadingFavStore = false;
        return res;
      }
    },
    isObject(val) {
      return typeof val === "object";
    },
    toLogin() {
      return this.$router.push('/login')
    }
  },
  computed: {
    StoreName() {
      return this.$t("message.store-list");
    },

    // ===== HIDE ITEMLIST PICKUP ======
    // itemListPickup() {
    //   return this.$store.state.itemList_module.itemListPickup;
    // },

    orderHistoryStore() {
      return this.$store.state.order_module.orderHistoryStore;
    },
    orderHistoryItem() {
      return this.$store.state.order_module.orderHistoryItem;
    },
    listStore() {
      return this.$store.state.shop_module.storeAll;
    },
    listProductLastSeen() {
      return this.$store.state.itemLastSeen_module.itemLastSeenResult;
    },
    listProductFav() {
      return this.$store.state.itemFavorite_module.itemFavoriteResult;
    },
    listProducts() {
      return this.$store.state.itemList_module.itemListResult;
    },
    listCategory() {
      return this.$store.state.allCategory_module.allCategory;
    },
    isLogin() {
      return $cookies.get("isLogin");
    },

    ...mapGetters({
      fetchItemFavs: "itemFavorite_module/FETCH_FAV_GETTER",
    }),
    lastPageStore() {
      return this.$store.state.shop_module.paginationStore.lastPage;
    },
    allStore() {
      //console.log(this.$store.state.shop_module.storeAll.slice(0,3));
      return this.$store.state.shop_module.storeAll.slice(0, 6);
    },
    shopNews() {
      return this.$store.state.shop_module.shopNews;
    },
    favStore() {
      return this.$store.state.shop_module.storeFav;
    },
  },
  // beforeCreate() {
  //   this.checkSessionLogin()
  // },
  created() {
    this.itemList();
    this.itemLastSeen();
    this.itemFavorite();
    this.dispatchOrderHistory();
    this.dispatchOrderHistoryStore();
    //this.category();
    this.handleIsLogin();
    //this.getAllStore();
    this.shopNewsList();
    this.getFavStore();
  },
};
</script>
<style scoped>
.style-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.class-category {
  margin-top: 20px;
  background-color: #ff0090;
  width: 100%;
  height: auto;
  padding-bottom: 50px;
}
.card-item {
  justify-content: center;
}
.row-scroll::-webkit-scrollbar {
  width: 0;
  display: none;
}
#home {
  padding: 0;
  margin: 0;
}
.parent-style-product {
  width: 60%;
  padding: 4px;
}
.text_title {
  border-left: 10px solid #ff0090;
  font-size: 18px;
}

.v-window__prev,
.v-window__next {
  z-index: -100 !important;
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .top-banner {
    margin-top: 5em;
  }
}

@media only screen and (max-width: 599px) {
  .top-banner {
    margin-top: 3.5em;
  }
}

@media only screen and (min-width: 961px) {
  .top-banner {
    margin-top: 3.5em;
  }
}
</style>
